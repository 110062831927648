import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  viewBox: "0 0 20 20",
  fill: "none",
  xmlns: "http://www.w3.org/2000/svg"
}

export function render(_ctx, _cache) {
  return (_openBlock(), _createElementBlock("svg", _hoisted_1, _cache[0] || (_cache[0] = [
    _createElementVNode("path", {
      d: "M16.457 6.875C16.457 10.3925 14.0129 12.4158 12.1812 14.375C11.0845 15.5483 10.207 19.375 10.207 19.375C10.207 19.375 9.33203 15.5508 8.23786 14.3808C6.40453 12.4225 3.95703 10.3958 3.95703 6.875C3.95703 5.2174 4.61551 3.62768 5.78761 2.45558C6.95972 1.28348 8.54943 0.625 10.207 0.625C11.8646 0.625 13.4543 1.28348 14.6264 2.45558C15.7986 3.62768 16.457 5.2174 16.457 6.875Z",
      stroke: "currentColor",
      "stroke-width": "1.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1),
    _createElementVNode("path", {
      d: "M10.207 9.375C11.5877 9.375 12.707 8.25571 12.707 6.875C12.707 5.49429 11.5877 4.375 10.207 4.375C8.82632 4.375 7.70703 5.49429 7.70703 6.875C7.70703 8.25571 8.82632 9.375 10.207 9.375Z",
      stroke: "currentColor",
      "stroke-width": "1.25",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }, null, -1)
  ])))
}
export default { render: render }